.Button-content {
  font-size: 16px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &.Button-fullWidth {
    width: 100%;
  }

  .Button-icon {
    display: flex;
    justify-content: center;
    margin-right: 16px;
    height: 18px;
    width: 18px;
  }

  .Button-endRowIcon {
    display: flex;
    margin-right: 16px;
    margin-left: auto;
  }

  &.Button-busy {
    display: flex;
    justify-content: center;
  }
}

.Button-disabled {
  opacity: 0.7;
}

.Button-root {
  padding: 5px;
  text-transform: initial;

  .MuiButton-root {
    width: 100%;
  }

  .MuiButton-text {
    padding: 0;
  }

  .MuiButton-label {
    width: 100%;
  }
}
