@import "../../../Themes/theme.scss";

.LoginPage-root {
  .LoginPage-welcomeText {
    font-size: 64px;
    font-weight: lighter;
    color: $loginPageTextColor;
  }

  .LoginPage-helpText {
    font-size: 32px;
    color: $loginPageTextColor;
  }

  .LoginPage-actions {
    margin-top: 21px;
    .LoginPage-loginInput {
      margin-bottom: 26px;
      height: 45px;
    }

    .LoginPage-passwordInput {
      margin-bottom: 16px;
      height: 45px;
    }

    .LoginPage-forgotButton {
      margin-bottom: 16px;
      width: fit-content;
      margin-left: auto;
    }
  }
}
