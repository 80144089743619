@import "../../../../Themes/theme.scss";

//* c-p-i for custom pagination item
.c-p-i {
  width: 32px;
  height: 32px;
  &.MuiButtonBase-root {
    margin-left: 8px;
    margin-right: 8px;
  }
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  justify-items: center;

  color: $paginationItemTextColor;

  //text style
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &.selected {
    border: solid 2px $paginationItemSelectedBorderBackgroundColor;
    border-radius: 6px;
    background-color: $paginationItemSelectedBackgroundColor;
    color: $paginationItemSelectedTextColor;
  }

  &.previous {
    margin-left: 16px;
    margin-right: 16px;
  }
  &.next {
    margin-left: 16px;
    margin-right: 16px;
  }

  &.disabled {
    opacity: 0.5;
  }
}
