@import "../../../Themes/theme.scss";

.ResetPasswordPage-infoText {
  color: $resetpasswordPageTextColor;
  font-size: 32px;
  margin-bottom: 21px;
}

.ResetPasswordPage_Password {
  margin-bottom: 26px;
  height: 45px;
}

.ResetPasswordPage_PasswordConfirm {
  margin-bottom: 26px;
  height: 45px;
}

.ResetPasswordPage_submit {
  margin-bottom: 16px;
}
