.RTELinkInput-root {
  padding: 5px;
  .RTELinkInput-input {
    height: 100%;
    margin-bottom: 3px;

    ::placeholder {
      font-size: 16px;
      opacity: 0.5;
    }
  }
}
