@import "../../Themes/theme.scss";

.ArtWorkListPage-root {
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 10px;

  .ArtWorkListPage-actions {
    display: flex;
    justify-content: space-between;

    .ArtWorkListPage-search {
      width: 334px;
      margin-right: 24px;
    }

    .ArtWorkListPage-filter {
      width: 236px;
      margin-right: auto;
    }
  }

  .ArtWorkListPage-header-artWorkTitle-button {
    .ArtWorkListPage-header-artWorkTitle {
      font-size: 16px;
      font-weight: bold;
      margin-right: 16px;
    }

    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .ArtWorkListPage-header-artWorkArtist-button {
    .ArtWorkListPage-header-artWorkArtist {
      font-size: 16px;
      font-weight: bold;
      margin-right: 16px;
    }

    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .ArtWorkListPage-header-artWorkStatus-button {
    .ArtWorkListPage-header-artWorkStatus {
      font-size: 16px;
      font-weight: bold;
      margin-right: 16px;
    }

    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
