@import "../../../Themes/colors.scss";
@import "../../../Themes/fonts.scss";

.InputWrapper-root {
  width: 100%;

  .Input-label {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;

    //*text style
    color: $inputTextLabelColor;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .ErrorMessage {
    color: $inputTextErrorColor;
  }
}
