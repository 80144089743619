.editor-button {
  width: 28px;
  height: 28px;

  padding: 2px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  color: #ccc;

  border: 1px solid #d4d4d4;

  font-size: 16px;

  &:hover,
  &.active {
    color: black;
    border-color: #034ea2;
  }

  > svg {
    font-size: 24px;
  }
}

.editor-icon {
  font-size: 18px;
  vertical-align: text-bottom;
}

.editor-instruction {
  white-space: pre-wrap;
  margin: 0 -20px 10px;
  padding: 10px 20px;
  font-size: 14px;
  background: #f8f8e8;
}

.editor-menu {
  display: flex;
  flex-direction: row;

  & > * + * {
    margin-left: 15px;
  }
}

.editor-toolbar {
  position: relative;

  padding: 10px 18px;
  margin: 0;

  border-bottom: 1px solid #949494;
}
