.SelectLanguageOptions_Container {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .SelectLanguageOptions_Option {
    cursor: pointer;
    width: 17px;
    height: 16px;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #010101;

    &.errors {
      color: red;
      border-bottom: 1px dashed red;
    }
  }
}
