@import "../../Themes/colors.scss";

.VideoListPage-root {
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 10px;

  .VideoListPage-actions {
    display: flex;

    .VideoListPage-addVideo {
      margin-left: auto;
    }
  }

  .VideoListPage-loader {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    width: fit-content;
  }

  .VideoListPage-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 36px;

    .VideoListPage-listElement {
      width: 341px;
      height: 270px;
      margin-right: 16px;
      background-color: $videoListPageItemBackgroundColor;
      color: $videoListPageItemTextColor;
      margin-bottom: 16px;
      cursor: pointer;
      overflow: hidden;

      &.VideoListPage-elementInProgress {
        opacity: 0.8;
      }

      .VideoListPage-videoInProgress {
        width: 341px;
        height: 192px;
        background-color: $videoListPageItemInProgressBackgroundColor;
      }

      video {
        width: 341px;
      }

      .VideoListPae-listElementDate {
        font-size: 14px;
        margin-bottom: 9px;
        margin-left: 14px;
        margin-top: 9px;
      }
      .VideoListPae-listElementTitle {
        font-size: 18px;
        margin-left: 14px;
        white-space: nowrap;
        margin-right: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
