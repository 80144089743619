@import "../../../Themes/theme.scss";

@mixin withBusyStyle($textColor) {
  .Button-busy {
    .loader-container .app-loader-inner > div {
      background-color: $textColor;
    }
  }
}

@mixin withButtonContentStyle {
  letter-spacing: 0;
  text-transform: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: bold;

  .Button-content {
    padding-left: 16px;
    padding-right: 16px;
    height: 54px;
    font-size: 16px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;

    &.Button-fullWidth {
      width: 100%;
    }

    .Button-icon {
      display: flex;
      justify-content: center;
      margin-right: 16px;
      height: 18px;
      width: 18px;
    }

    .Button-endRowIcon {
      display: flex;
      margin-right: 16px;
      margin-left: auto;
    }

    &.Button-busy {
      display: flex;
      justify-content: center;
    }
  }
}

.Button-disabled {
  opacity: 0.7;
}

.Button-root {
  padding: 0;
  text-transform: initial;

  .MuiButton-root {
    width: 100%;
  }

  .MuiButton-text {
    padding: 0;
  }

  .MuiButton-label {
    width: 100%;

    .Button-standard {
      @include withButtonContentStyle;
      @include withBusyStyle($buttonStandardTextColor);
      background-color: $buttonStandardBackgroundColor;
      color: $buttonStandardTextColor;
      border: 1px solid $buttonStandardBackgroundColor;

      min-width: 225px;

      &:hover {
        background-color: $buttonStandardBackgroundHoverColor;
      }
    }

    .Button-danger {
      @include withButtonContentStyle;
      background-color: $buttonDangerBackgroundColor;
      color: $buttonDangerTextColor;
      border: 1px solid $buttonDangerBorderColor;
      min-width: 144px;

      &:hover {
        background-color: $buttonDangerBackgroundHoverColor;
      }
    }

    .Button-success {
      @include withButtonContentStyle;
      @include withBusyStyle($buttonSuccessTextColor);
      background-color: $buttonSuccessBackgroundColor;
      color: $buttonSuccessTextColor;
      min-width: 225px;

      &:hover {
        background-color: $buttonSuccessBackgroundHoverColor;
      }
    }

    .Button-outlined {
      @include withButtonContentStyle;
      background-color: $buttonOutlinedBackgroundColor;
      border: 1px solid $buttonOutlinedBorderColor;
      color: $buttonOutlinedTextColor;

      &:hover {
        background-color: $buttonOutlinedBackgroundHoverColor;
      }
    }

    .Button-link {
      font-size: 14px;
      height: 20px;
      text-decoration: underline;
      text-transform: initial;
      color: $buttonLinkTextColor;
    }
  }
}
