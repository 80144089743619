.editor-image-root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    max-width: 100%;
  }
}
