@import "../../../../Themes/theme.scss";

.NavBarLink-root {
  height: 73px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  .NavBarLink-select {
    width: 13px;
    height: 100%;
  }

  .NavBarLink-title {
    color: $navBarTitleColor;
    font-size: 32px;
    margin-left: 57px;
  }

  &.active {
    .NavBarLink-select {
      background-color: $navBarItemSelectedColor;
      animation: pop 0.3s ease forwards;
    }

    .NavBarLink-title {
      color: $navBarTitleSelectedColor;
      animation: appear 0.3s ease forwards;
    }
  }
}

@keyframes pop {
  from {
    height: 0;
  }
  to {
    height: 73px;
  }
}

@keyframes appear {
  from {
    opacity: 0.5;
    margin-left: 0;
  }
  to {
    opacity: 1;
    margin-left: 57px;
  }
}
