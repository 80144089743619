@import "../../../Themes/theme.scss";

.CustomCard-root {
  .CustomCard-header {
    height: 41px;
    background-color: $cardHeaderBackgroundColor;
    color: $cardHeaderTextColor;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding-left: 11px;
  }

  .CustomCard-body {
    background-color: $cardBodyBackgroundColor;
    padding: 15px;
  }
}
