.UserDetailsPage-form {
  @media screen and (min-width: 1000px) {
    .row {
      display: flex;
      gap: 16px;

      .col {
        flex-grow: 1;
      }
    }

    .UserDetailsPage-save {
      max-width: 200px;
    }
  }

  .CustomInputText-root {
    margin-bottom: 20px;
  }

  .SelectInput-container {
    margin-bottom: 20px;
  }
}
