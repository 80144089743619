.image-progress-upload-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .upload-secondary-loading-message {
        font-family: Roboto, 'Roboto', sans-serif;
        font-size: 12px;
        font-style: italic;
    }
}

.rte-image-dropzone-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    font-family: Roboto, 'Roboto', sans-serif;

    .icon {
        margin-bottom: 10px;
        cursor: pointer;
    }

    .image-dropzone-title {
        color: #9ba2aa;
        font-size: 16px;
        margin-bottom: 10px;
        margin-left: 20px;
    }

    .dropzone-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        width: 100%;

        .actions {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
        }

        .dropzone {
            width: calc(100% - 10px);
            margin: auto;
            min-width: 320px;
            height: 194px;
            cursor: pointer;
            border: dashed 2px transparent;

            .image-dropzone-content {
                display: flex;
                flex: 1;
                height: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .preview-image-loadable-root {
                    display: flex;
                    flex: 1;
                    max-height: 100%;

                    .preview-image {
                        object-fit: contain;
                        height: 100%;
                        width: 100%;
                    }
                }

                .title {
                    font-size: 16px;
                    color: #9ba2aa;
                }
            }
        }

        .dropzone.disabled {
            cursor: not-allowed;
        }

        .dropzone.dashBorder {
            border: dashed 2px #d4dadf;
        }
    }
}
