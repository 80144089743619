.video-choice-root {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-bottom: 16px;

  .VideoChoice-loader {
    margin-top: 50px;
    margin-left: calc(50% - 57px / 2);
    margin-right: calc(50% - 57px / 2);
    width: fit-content;
  }

  .VideoChoice-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    height: 50vh;
    width: 920px;
    overflow: auto;

    .VideoChoice-listElement {

      &.VideoChoice-elementInProgress {
        opacity: 0.8;
      }

      video {
        width: 200px;
        max-height: 112.5px;
      }

      .VideoChoice-listElementDate {
        font-size: 14px;
        margin-bottom: 9px;
        margin-left: 14px;
        margin-top: 9px;
      }
      .VideoChoice-listElementTitle {
        font-size: 14px;
        margin-left: 7px;
        white-space: nowrap;
        margin-right: 7px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ic-buttons-container {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
  }
}
