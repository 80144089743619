@import "../../../Themes/theme.scss";

.NavBar-root {
  height: 100%;
  width: 296px;
  background-color: $navBarBackgroundColor;
  text-decoration: none;

  position: sticky;
  position: -webkit-sticky; /* Safari */

  top: 0;

  .NavBar-logo {
    margin-top: 45px;
    margin-bottom: 106px;
    display: flex;
    justify-content: center;
    img {
      width: 248.6px;
      height: 65.1px;
    }
  }
}
