$tango: #ee7624;
$gray_chateau: #9ba2aa;
$alizarin_crimson: #e22926;
$web_orange: #ffa900;
$havelock_blue: #469ddc;
$verdun_green: #397e00;
$oxford_blue: #313d4e;
$aqua_haze: #f0f4f7;
$abbey: #565a5e;
$transparent_blue: rgba(43, 47, 164, 0.18);

$black: #000000;
$black_39: #393939;
$black_44: #444444;
$black_84: #848484;
$black_bf: #bfbfbf;
$black_e4: #e4e4e4;
$black_f5: #f5f5f5;
$white: #ffffff;

//! COMPONENTS

//* Loader
$loaderBackgroundColor: $tango;

//* Loading page
$loadingPageBackgroundColor: $white;

//* Profile header
$buttonLinkTextColor: $havelock_blue;

//* Page layout
$pageLayoutBackgroundColor: $white;
$pageLayoutNavBarBackgroundColor: $white;
$loginPageLayoutTitleTextColor: $tango;

//* Buttons
$buttonStandardTextColor: $white;
$buttonStandardBackgroundColor: $tango;
$buttonStandardBackgroundHoverColor: rgba($tango, 0.7);

$buttonDangerBackgroundColor: $white;
$buttonDangerTextColor: $alizarin_crimson;
$buttonDangerBorderColor: $alizarin_crimson;
$buttonDangerBackgroundHoverColor: rgba($black, 0.05);

$buttonSuccessBackgroundColor: $verdun_green;
$buttonSuccessTextColor: $white;
$buttonSuccessBackgroundHoverColor: rgba($verdun_green, 0.7);

$buttonOutlinedBackgroundColor: $white;
$buttonOutlinedTextColor: $black_44;
$buttonOutlinedBackgroundHoverColor: rgba($black, 0.05);
$buttonOutlinedBorderColor: $black_44;

$buttonLinkTextColor: $havelock_blue;

//* Select
$selectBorderColor: $tango;
$selectBackgroundColor: $white;
$selectInputTextColor: $tango;
$selectIconBackgroundColor: $white;
$selectIconDisabledBackgroundColor: $white;
$selectEmptyBackgroundColor: $white;
$selectEmptyInputTextColor: rgba($black, 0.5);
$selectErrorBorderColor: $alizarin_crimson;

//* Switch
$switchLabelColor: $abbey;
$switchUncheckedColor: rgba($black_84, 0.5);
$switchCheckedColor: rgba($tango, 0.5);
$switchThumbColor: $tango;

//* Toast
$customToastTextSuccessColor: $verdun_green;
$customToastBackgroundSuccessColor: $verdun_green;

$customToastTextErrorColor: $alizarin_crimson;
$customToastBackgroundErrorColor: $alizarin_crimson;

$customToastTextInfoColor: $tango;
$customToastBackgroundInfoColor: $tango;

$customToastBodyBackgroundColor: $white;
$customToastContentTextColor: $black;

//* Input
$inputTextLabelColor: $abbey;
$inputTextTextColor: $abbey;
$inputTextErrorColor: $alizarin_crimson;

$inputTextStandardBackgroundColor: $white;
$inputTextStandardBorderColor: $black_e4;
$inputTextStandardBorderColorFocus: $black_44;

$inputTextOutlinedBackgroundColor: $white;
$inputTextOutlinedBorderColor: $black_bf;
$inputTextOutlinedBorderColorFocus: $black_44;

$inputTextFilledBackgroundColor: $black_e4;
$inputTextFilledLabelColor: $tango;

//* Media input
$mediaInputItemBackgroundColor: $white;
$mediaInputItemBorderColor: $tango;
$mediaInputErrorBorderColor: $alizarin_crimson;
$mediaInputdocumentBackgroundColor: $white;

//* No results
$noResultTextColor: $tango;

//* Paginated table
$paginatedTableBorderColor: $tango;
$paginatedTableHeaderTextColor: $tango;
$paginatedTableHeaderBackgroundColor: $white;
$paginatedTableBackgroundColor: $white;
$paginatedTableRowItemTextColor: $oxford_blue;
$paginatedTableRowBackgroundColor: $aqua_haze;
$paginationItemTextColor: $oxford_blue;
$paginationItemSelectedBorderBackgroundColor: $oxford_blue;
$paginationItemSelectedBackgroundColor: $white;
$paginationItemSelectedTextColor: $oxford_blue;

//* Popover menu
$popoverLabelTextColor: $oxford_blue;

//* Search
$searchInputBackgroundColor: $white;
$searchInputBackgroundColorFocus: $white;
$searchBorderColor: $tango;

//* Tabs
$tabTextColor: $gray_chateau;
$tabSelectedTextColor: $tango;
$tabSelectedUnderlineColor: $tango;
$tabUnderlineColor: $tango;
$tabBackgroundColor: $black_f5;

//* Card
$cardHeaderBackgroundColor: $tango;
$cardHeaderTextColor: $white;
$cardBodyBackgroundColor: $white;

//* Radio
$radioGroupLabelTextColor: $abbey;
$radioGroupErrorColor: $alizarin_crimson;
$radioLabelColor: $oxford_blue;
$radioLabelCheckedColor: $oxford_blue;
$radioEffectColor: $abbey;

//* Dialogs
$dialogHeaderBackgroundColor: $black_e4;
$dialogHeaderTextColor: $tango;
$dialogTextColor: $oxford_blue;

//! PAGES

//* Login page
$loginPageNavBarBackgroundColor: $tango;
$loginPageTextColor: $black_39;

//* Reset password page
$resetpasswordPageTextColor: $tango;

//* AuthenticatedPage layout

//* Navbar
$navBarBackgroundColor: $tango;
$navBarItemSelectedColor: $white;
$navBarTitleColor: rgba($white, 0.5);
$navBarTitleSelectedColor: $white;

//* Header
$headerTitleColor: $black_39;

//* ArtWork information tab
$artWorkInformationTabBrouillonColor: $web_orange;
$artWorkInformationTabPublishedColor: $verdun_green;
$artWorkInformationTabCancelledColor: $alizarin_crimson;

//* Categories details page
$categoriesDetailsPageTextColor: $tango;

//* Videos List Page
$videoListPageItemBackgroundColor: $tango;
$videoListPageItemTextColor: $white;
$videoListPageItemInProgressBackgroundColor: $aqua_haze;
