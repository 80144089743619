@import "./colors.scss";
@import "./dimens.scss";
@import "./fonts.scss";
@import "./mixins.scss";

//* MAIN
html,
body {
  font-family: Roboto, "Roboto", sans-serif;
  height: 100%;

  #root {
    margin: 0;
    height: 100%;
    background-color: $pageLayoutBackgroundColor;
  }

  .MuiPaper-rounded {
    border-radius: 0;
  }
}
