.image-choice-root {
  padding: 16px;

  .ic-buttons-container {
    margin-top: 16px;
    display: flex;
    flex-direction: row;

    .ic-button-root {
      display: flex;
      flex: 1;
    }
  }
}
