@import "../../../Themes/theme.scss";

.Header-root {
  height: 120px;
  min-height: 120px;
  width: 100%;
  padding-top: 49px;
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  justify-content: space-between;

  .Header-breadCrumb {
    display: flex;
    align-items: center;

    .Header-previousTitleLink {
      cursor: pointer;
      color: $headerTitleColor;
      text-decoration: none;
      font-size: 28px;
      margin-right: 20px;
    }

    img {
      height: 19px;
    }

    .Header-title {
      color: $headerTitleColor;
      font-size: 28px;
      margin-left: 20px;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  .Header-title {
    color: $headerTitleColor;
    font-size: 48px;
  }
}
