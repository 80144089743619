@import "../../../Themes/theme.scss";

.Tabs-root {
  height: 100%;
  max-height: 100%;
  padding-top: 10px;

  .Tabs-withButtons {
    display: flex;
    margin-right: 46px;
    height: 48px;

    .MuiTabs-root {
      margin-left: 39px;
      margin-right: auto;
      min-width: none;
      height: 100%;

      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          .MuiTab-root {
            min-width: 0;
          }

          .MuiButtonBase-root {
            min-width: none;

            padding: 0;
            display: flex;
            justify-content: flex-start;
            color: $tabTextColor;
            margin-right: 80px;

            &.Mui-selected {
              color: $tabSelectedTextColor;
              font-weight: bold;
            }
            .MuiTab-wrapper {
              width: fit-content;
              font-size: 17px;
              font-weight: normal;
              letter-spacing: -0.41px;
            }
          }
        }
        > span {
          background-color: $tabSelectedUnderlineColor;
          height: 5px;
        }
      }
    }

    .Tabs-borderBottom {
      width: 100%;
      background-color: $tabUnderlineColor;
      height: 1px;
      position: fixed;
      transform: translateY(45px);
    }
  }

  .Tabs-children {
    height: calc(100% - 48px);
    background-color: $tabBackgroundColor;
    overflow: auto;
  }
}
