.progress-upload-container {
  display: flex;
  flex: 1;
  width: 100%;
  min-width: 300px;
  max-width: 500px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;

  .cancel-label {
    margin-top: 16px;
    cursor: pointer;
  }

  .cancel-label:hover {
    opacity: 0.6;
  }
}
