@import "../../../Themes/theme.scss";

.ForgotPasswordPage-helpText1 {
  font-size: 32px;
  color: $loginPageTextColor;
  margin-bottom: 50px;
}

.ForgotPasswordPage-helpText2 {
  font-size: 18px;
  color: $loginPageTextColor;
  margin-bottom: 21px;
}

.ForgotPasswordPage-email {
  margin-bottom: 26px;
  height: 45px;
}

.ForgotPasswordPage-submit {
  margin-bottom: 16px;
}
