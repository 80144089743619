@import "../../../Themes/theme.scss";

.Search-root {
  width: 330px;

  .Search-formControl {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $searchBorderColor;

    &:focus-within {
      border-bottom: 2px solid $searchBorderColor;
    }

    .search-input {
      background-color: $searchInputBackgroundColor;
      height: 54px;

      .Search-startAdornment {
        margin-left: -6px;
        margin-right: 8px;
        align-self: center;
        height: 16px;
      }

      .Search-endAdornment {
        display: flex;

        .Search-EndAdornmentClear {
          align-self: center;
          height: 30px;
          width: 30px;
          padding: 0;

          .MuiIconButton-label {
            width: fit-content;
            height: fit-content;
            img {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }

    .Search-button {
      height: 45px;
      width: 45px;
      margin-top: auto;
      margin-bottom: auto;
      left: 10px;

      img {
        height: 24px;
        width: 24px;
      }
    }
  }
}
