@import "../../../Themes/theme.scss";

.NoResult-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $noResultTextColor;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;

  height: 400px;
  justify-content: center;

  img {
    margin-top: 50px;
    margin-bottom: 42px;
  }
}
