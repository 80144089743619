.link-choice-root {
  padding: 16px;

  .RTELinkInput-root {
    margin-bottom: 30px;
  }

  .lc-buttons-container {
    margin-top: 16px;
    display: flex;
    flex-direction: row;

    .lc-button-root {
      display: flex;
      flex: 1;
    }
  }
}
