@import "../../../../Themes/theme.scss";

.CustomRadioButton-root {
  display: flex;
  flex-direction: row;
  align-items: center;

  .CustomRadioButton-label {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $radioLabelColor;

    &.CustomRadioButton-label-checked {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $radioLabelCheckedColor;
    }
  }

  .CustomRadioButton-radio-root {
    color: $radioEffectColor;
  }

  .CustomRadioButton-radio-disabled {
    opacity: 0.6;
  }
}
