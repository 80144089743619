@import "../../../Themes/theme.scss";

.CustomRadioGroup-root {
  width: 100%;
  height: 54px;

  .CustomRadioGroup-label {
    display: flex;
    justify-content: flex-start;

    //*text style
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $radioGroupLabelTextColor;

    &.error {
      color: $radioGroupErrorColor;
    }
  }

  .CustomRadioGroup-group {
    width: 100%;
    .CustomRadioGroup-items {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;

      .CustomRadioGroup-item {
        margin-right: 36px;
      }
    }
  }

  .CustomRadioGroup-error-message {
    font-size: 16px;
    margin-top: 5px;
    color: $radioGroupErrorColor;
  }
}
