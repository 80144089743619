@import '../../../Themes/theme.scss';

.popover-menu {
    .pm-button-root {
        display: flex;
        align-items: center;
        cursor: pointer;

        .pm-button-menu {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $popoverLabelTextColor;
        }

        .pm-button-expand-icon {
            margin-left: $spacingSmall;

            transition: transform 200ms ease-in-out;

            &.open {
                transform: rotate(180deg);
            }
        }
    }

    .pm-popover-paper {
        display: flex;
        flex-flow: column;
    }
}
