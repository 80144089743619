@import "../../Themes/theme.scss";

.ExhibitionsListPage-root {
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 10px;

  .ExhibitionsListPage-actions {
    display: flex;

    .ExhibitionsListPage-search {
      width: 334px;
      margin-right: auto;
    }
  }

  .ExhibitionsListPage-header-exhibitionsName-button {
    .ExhibitionsListPage-header-exhibitionsName {
      font-size: 16px;
      font-weight: bold;
      margin-right: 16px;
    }

    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
