@import "../../../Themes/colors.scss";

@mixin item {
  background-color: $mediaInputItemBackgroundColor;
  border-radius: 8px;
  margin-right: 14px;
  margin-bottom: 14px;
  display: flex;
  border: 2px solid $mediaInputItemBorderColor;
  height: 94px;
  width: 94px;
}

.FilesInput-root {
  .FilesInput-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: fit-content;

    //Cancel item row margin
    margin-bottom: -14px;
    margin-right: -14px;

    .add-document-button {
      background-color: $buttonStandardBackgroundColor;
      color: $buttonStandardTextColor;
      padding: 12px 20px;

      img {
        margin-right: 10px;
      }
    }

    .add-file-button {
      @include item();

      img {
        margin-left: 9px;
        margin-bottom: 5px;
      }

      &.Mui-disabled {
        opacity: 0.5;
      }
    }

    .document-item {
      display: flex;
    }
  }
}

.MediaInput-root {
  .FilesInput-list {
    height: fit-content;
    width: fit-content;
    .media-item {
      @include item();
      position: relative;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: max-content;
      width: max-content;
      border: none;

      &.error {
        border: 2px solid $mediaInputErrorBorderColor;
      }

      .MuiButtonBase-root {
        padding: 0;
      }

      .preview {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        object-fit: cover;
        max-width: 400px;
        max-height: 400px;
      }

      .buttons {
        width: 100%;
        display: flex;
        margin-top: 20px;

        :first-child {
          margin-left: auto;
          margin-right: 10px;
        }

        :last-child {
          margin-left: 10px;
          margin-right: auto;
        }
      }
    }
  }
}

.DocumentInput-root {
  .FilesInput-list {
    display: flex;
    flex-direction: column;

    .document-item {
      background-color: $mediaInputdocumentBackgroundColor;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      max-width: 100%;

      button {
        margin: 0 8px;

        &:disabled {
          opacity: 0.4;
        }
      }

      a.filename {
        text-decoration: none;
        color: inherit;
        padding: 0 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
