.VideoDetailsPage-root {
  .VideoDetailsPage-content {
    display: flex;

    .VideoDetailsPage-contentLeft {
      width: 40%;
      margin-top: 5%;
      margin-left: 36px;
      margin-right: 36px;

      .VideoDetailsPage-title {
        .CustomInputText-input {
          height: fit-content;
        }
      }

      .VideoDetailsPage-contentLeft-thumbnail {
        margin-top: 36px;
        display: flex;

        .VideoDetailsPage-thumbnailOffset {
          width: 100%;
        }
      }
    }

    video {
      height: 300px;
      margin-top: 5%;
      margin-bottom: 20px;
    }

    .VideoDetailsPage-videoInput {
      margin-top: 5%;
      height: 50vh;
    }
  }

  .VideoDetailsPage-actions {
    width: fit-content;
    margin-left: auto;
    margin-right: 36px;
  }
}
