@import "../../../Themes/theme.scss";

.CustomSwitch-wrap {
  display: flex;
  align-items: center;
  height: 54px;
  margin-bottom: 20px;

  .CustomSwitch-label {
    margin-right: 43px;
    font-size: 14px;
    color: $switchLabelColor;
  }
  .CustomSwitch-root {
    height: 28px;
    width: 56px;
    cursor: pointer;

    .CustomSwitch-track {
      background-color: $switchUncheckedColor;
      border-radius: 14px;
      width: 56px;
      height: 28px;
      animation: trackColorOut ease forwards 0.2s;
    }

    .CustomSwitch-trackChecked {
      animation: trackColor ease forwards 0.2s;
    }

    .CustomSwitch-thumb {
      height: 24px;
      width: 24px;
      background-color: $switchThumbColor;
      border-radius: 12px;
      position: relative;
      top: -26px;
      left: 3px;
      transition-property: left 1s;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      animation: ease forwards slideOut 0.2s;
    }

    .CustomSwitch-thumbChecked {
      animation: ease forwards slide 0.2s;
    }
  }
}

.CustomSwitch-disabled {
  opacity: 0.5;
}

@keyframes slide {
  from {
    left: 3px;
  }
  to {
    left: 29px;
  }
}

@keyframes slideOut {
  from {
    left: 29px;
  }
  to {
    left: 3px;
  }
}

@keyframes trackColor {
  from {
    background-color: $switchUncheckedColor;
  }
  to {
    background-color: $switchCheckedColor;
  }
}

@keyframes trackColorOut {
  from {
    background-color: $switchCheckedColor;
  }
  to {
    background-color: $switchUncheckedColor;
  }
}
