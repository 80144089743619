.editor-toolbar {
	position: relative;
	padding: 10px 18px;
	margin: 0;
	border-bottom: 1px solid #949494;
	display: flex;

	& > * + * {
		margin-left: 15px;
	}
}
