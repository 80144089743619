@import "../../../../Themes/theme.scss";

.CustomDialog-root {
  min-width: 300px !important;

  .CustomDialog-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dialogHeaderTextColor;
    background-color: $dialogHeaderBackgroundColor;
    font-size: 24px;
    font-weight: bold;

    .CustomDialog-header-closeButton {
      height: 32px;
      width: 32px;

      img {
        height: 16px;
        width: 16px;
      }
    }
  }

  .CustomDialog-body {
    padding: 20px;
    color: $dialogTextColor;
  }

  .CustomDialog-actions {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-width: 600px;

    .Button-root {
      width: 200px;
    }
  }
}
