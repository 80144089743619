.editor-video-root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .editor-video-wrapper {
    position: relative;
    video {
      max-width: 100%;
      width: 500px;
    }
    .controls {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-evenly;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      bottom: 15px;
      width: 90%;
      max-width: 500px;
      background: rgba(0, 0, 0, 0.25);
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(4px);
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.18);
      .actions button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        padding-top: 4px;
        .icon {
          background-color: none;
          color: white;
          font-size: 25px;
        }
      }

      .velocity {
        appearance: none;
        background: none;
        color: white;
        outline: none;
        border: none;
        text-align: right;
        direction: rtl;
        font-size: 16px;
        padding-right: 10px;
      }

      .mute-btn {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        padding-top: 4px;
        .icon {
          background-color: none;
          color: white;
          font-size: 20px;
        }
      }
    }
  }
}
