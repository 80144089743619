//* DIMENS

$spacing: 16px;
$spacingSmall: 4px;
$spacingMedium: 10px;
$spacingLarge: 20px;

$fontSize: 16px;
$fontSizeSmall: 12px;
$fontSizeMedium: 16px;
$fontSizeLarge: 24px;

$navbarWidth: 242px;
$navbarMenuItemHeight: 64px;
