@import "../../../Themes/theme.scss";

@mixin CustomInputText-inputBaseStyle {
  width: 100%;
  height: 100%;
  font-size: 18px;
  transition: all 200ms;
  color: $inputTextTextColor;
  font-family: Roboto, "Roboto", sans-serif;

  &:focus {
    outline: none;
  }
}

.CustomInputText-root {
  .CustomInputText-label {
    font-size: 14px;
    color: $inputTextTextColor;
    margin-bottom: 4px;
    height: 16px;

    &.filled {
      color: $inputTextFilledLabelColor;
    }
  }

  .CustomInputText-input {
    height: 100%;

    textarea {
      max-width: 100%;
      max-height: 200px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    ::placeholder {
      font-size: 16px;
      opacity: 0.5;
    }

    &.standard {
      input,
      textarea {
        @include CustomInputText-inputBaseStyle;

        border: none;
        border-bottom: 1px solid $inputTextStandardBorderColor;
        padding-left: 4px;
        padding-right: 4px;
        height: 34px;

        &:focus {
          border-bottom: 1px solid $inputTextStandardBorderColorFocus;
        }
      }

      &.CustomInputText-error {
        input {
          border-bottom: 1px solid $inputTextErrorColor;
        }
      }
    }

    &.outlined {
      input,
      textarea {
        @include CustomInputText-inputBaseStyle;

        border: 1px solid $inputTextOutlinedBorderColor;
        border-radius: 4px;
        padding-left: 12px;
        padding-right: 12px;

        &:focus {
          border: 1px solid $inputTextOutlinedBorderColorFocus;
        }
      }

      &.CustomInputText-error {
        input,
        textarea {
          border: 1px solid $inputTextErrorColor;
        }
      }
    }

    &.filled {
      input,
      textarea {
        @include CustomInputText-inputBaseStyle;

        border: none;
        padding-left: 12px;
        padding-right: 12px;
        background-color: $inputTextFilledBackgroundColor;
      }

      &.CustomInputText-error {
        input {
          border: 1px solid $inputTextErrorColor;
        }
      }
    }
  }

  .CustomInputText-error {
    color: $inputTextErrorColor;
  }
  .CustomInputText-errorMessage {
    color: $inputTextErrorColor;
    height: 0;
  }
}
