@import "../../../Themes/theme.scss";

.SelectInput-container {
  .Input-formControl {
    width: 100%;
  }

  .Select-root {
    height: 54px;
    width: inherit;
    border: 1px solid $selectBorderColor;
    background-color: $selectBackgroundColor;

    .Select-input {
      background-color: transparent;
      color: $selectInputTextColor;
      width: 100%;
      height: 100%;

      .Select-placeholder-text {
        display: flex;
        align-items: center;
        height: 100%;
        opacity: 0.5;
      }

      .Select-item-title {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 19px;
      }
    }

    .MuiSelect-icon {
      top: 0;
      .Select-icon {
        height: 52px;
        width: 52px;
        background-color: $selectIconBackgroundColor;
        display: flex;
        justify-content: center;
        align-items: center;

        &.Mui-disabled {
          opacity: 0.5;
          background-color: $selectIconDisabledBackgroundColor;
        }
      }
    }

    .MuiSelect-iconOpen {
      border-radius: 10px 0 0 10px;
    }
  }

  .Select-empty {
    background-color: $selectEmptyBackgroundColor;

    .Select-input {
      color: $selectEmptyInputTextColor;
    }
  }

  .Select-error {
    border: 1px $selectErrorBorderColor solid;
  }
}
