@import "../../../../Themes/theme.scss";

.app-loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100%;
  background-color: $loadingPageBackgroundColor;

  .content-container {
    margin: 15vh 0;

    .app-logo {
      margin-bottom: 10vh;
    }
  }
}
