@import "../../../Themes/theme.scss";

.NewsDetailsPage-card {
  margin: 30px;
}

.NewsFormContainer {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
  }

  .col {
    flex-basis: 100%;
    padding: 25px;

    .CustomInputText-root {
      margin-bottom: 20px;
    }

    .SelectInput-container {
      margin-bottom: 20px;
    }
  }
}

.NewsDetailsPage-actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
  width: 100%;

  .NewsDetailsPage-actions-btn {
    min-width: 200px;
  }
}

.NewsDetailsPage_NewsDescription-input textarea {
  height: 200px !important;
}
