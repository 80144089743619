@import "../../../Themes/theme.scss";

.AuthPageLayout-root {
  display: flex;
  height: 100%;

  .AuthPageLayout-navBar {
    background-color: $loginPageNavBarBackgroundColor;
    width: 286px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 215.8px;
      height: 294.3px;
    }
  }

  .AuthPageLayout-content {
    width: 340px;
    margin: auto;
  }
}
