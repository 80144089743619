@import "../../Themes/theme.scss";

.AuthenticatedPageLayout-root {
  height: 100vh;
  display: flex;

  .AuthenticatedPageLayout-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 296px);
    height: 100%;
    max-height: 100%;

    .AuthenticatedPageLayout-children {
      height: calc(100% - 120px);
      overflow: auto;
    }

    .AuthenticatedPageLayout-children-padding {
      padding-left: 36px;
      padding-right: 36px;
      padding-top: 10px;
    }
  }
}
