@import "../../../../Themes/theme.scss";

.pl-root {
  margin-top: 28px;
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
  flex: 1;

  .p1-table-wrapper {
    border: 1px solid $paginatedTableBorderColor;
    .pl-table {
      .pl-header {
        .pl-header-row {
          height: 50px;
          .pl-header-cell {
            color: $paginatedTableHeaderTextColor;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            background-color: $paginatedTableHeaderBackgroundColor;
          }

          .pl-header-cell:first-child {
            border-radius: 8px 0 0 0;
          }

          .pl-header-cell:last-child {
            border-radius: 0 8px 0 0;
          }
        }
      }

      .pl-table-body {
        > tr {
          background-color: $paginatedTableBackgroundColor;
          height: 50px;
          color: $paginatedTableRowItemTextColor;

          > td {
            border-top: none;
            border-bottom: none;
          }

          &.clickable {
            cursor: pointer;
          }
        }

        > tr:nth-child(2n-1) {
          background-color: $paginatedTableRowBackgroundColor;
        }

        > tr:last-child {
          border-radius: 0 0 8px 8px;
          > td:first-child {
            border-radius: 0 0 0 8px;
          }
          > td:last-child {
            border-radius: 0 0 8px 0;
          }
        }
      }
    }
  }

  .pl-loader {
    margin-top: 50px;
  }

  .pl-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    margin-top: 59px;
    margin-bottom: 48px;
  }
}

.p1-root-empty {
  margin-bottom: 60px;
}
